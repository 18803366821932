.mod {
  text-decoration: none;
}

ins.diffins,
ins.diffmod {
  background-color: limegreen;
  text-decoration: none;
}

del.diffdel,
del.diffmod {
  background-color: crimson;
  text-decoration: line-through;
}
