.actions-overlay {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 0;
  padding-top: 4px;
  padding-right: 6px;
  z-index: 10;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
}

.docGrid-fileIcon-hover:hover .actions-overlay {
  opacity: 1;
}

.doc-type-icon-list-sizing {
  max-width: 34px;
  max-height: 34px;
}

.version-history-btn {
  all: unset;
  cursor: pointer;
}

.version-history-btn:hover {
  text-decoration: underline;
  color: blue;
}
