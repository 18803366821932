.gallery-actions-overlay {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 0;
  padding-top: 4px;
  padding-right: 6px;
  z-index: 10;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
}

.gallery-fileIcon-hover:hover .gallery-actions-overlay {
  opacity: 1;
}
