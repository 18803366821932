body {
  background-color: rgb(234, 234, 234) !important;
}

body,
html {
  height: auto;
}

/*timepicker style*/
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
  width: 100px;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

/*home page styles*/

.masthead {
  background-image: linear-gradient(
    135deg,
    var(--brand-color) 30%,
    rgb(118, 118, 118) 50%
  ) !important;
}

.masthead.segment {
  min-height: 700px;
  padding: 1em 0 !important;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
  display: inline-block;
}

.masthead h1.ui.header {
  margin-top: 3em;
  margin-bottom: 0;
  font-size: 4em;
  font-weight: normal;
  display: inline-block;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
  display: inline-block;
}

.secondary.inverted.pointing.menu {
  border: none;
}

/*end home page styles*/

/* navbar styles */

.ui.menu .item img.logo {
  margin-right: 1.5em;
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(255, 255, 255) 30%,
    var(--brand-color-lightest),
    var(--brand-color-light),
    var(--brand-color)
  ) !important;
}

.ui.main.container,
.main.segment {
  margin-top: 2em;
}

.ui.center.aligned.segment.attendance-preview {
  background-color: #f5f5f5;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.ui.menu .item > img:not(.ui) {
  margin-right: 1.5em !important;
}

.ui.menu:not(.vertical) .item > .button {
  margin-left: 0.5em;
}

/*chat comments*/

.ui.comments .comment .comments {
  padding-bottom: 0 !important;
  padding-left: 2em !important;
}

/* Dialog Boxes  */
#root .redux-toastr .rrt-confirm-holder .rrt-confirm {
  overflow: revert;
}

div.ui.superMainContainer {
  width: 98%;
}
